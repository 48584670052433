<template>
  <p>
    <strong>Как пройти тест?</strong> &ndash; На Главной странице по кнопке
    &laquo;Перейти к тесту&raquo; или зайти во вкладку Текущее тестирование,
    начать тестирование и ответить на все вопросы теста.
  </p>
  <p>
    <strong>Что делать, если не получается войти в личный кабинет?</strong>
    &ndash; сделайте восстановление пароля по электронной почте по адресу
    <a href="https://desk.eiskills.kz/">https://desk.eiskills.kz/</a>.
  </p>
  <p>
    <strong>Где можно увидеть отчет?</strong> &ndash; на Главной странице по
    кнопке &laquo;Получить отчет&raquo;
  </p>
  <p>
    <strong>Где проходить курсы?</strong> &ndash; для этого необходимо зайти во
    вкладку Курсы (вкладка видна, если у вас есть активные доступы к курсам)
  </p>
  <p>
    <strong
      >Что делать, если вы сотрудник компании и прошли тест, но вам не показался
      отчет на главной странице</strong
    >
    &ndash; Спросите ответственного за выдачу отчетов.
  </p>
  <p>
    <strong
      >Что делать, если вы сотрудник компании и должны были получить курс, но
      вкладка курсы не активна или никакой курс не открылся</strong
    >
    - Спросите ответственного за выдачу курсов.
  </p>

  <h3>Вкладки</h3>
  <p>
    <strong>Главная</strong> - главная страница личного кабинета, здесь можно
    посмотреть отчет, сформированный по результатам теста, также можно увидеть
    доступные для прохождения курсы.
  </p>
  <p>
    <strong>Текущее тестирование</strong> - тестирование ЭИ, которое можно
    пройти в личном кабинете, после прохождения будет сформирован отчет.
  </p>
  <p>
    <strong>Курсы</strong> &ndash; вкладка, в которой открываются курсы, которые
    доступны для прохождения
  </p>
</template>

<script>
export default {
  name: "staffFAQ",
};
</script>

<style lang="scss" scoped>
@import "./../../assets/css/faq";
</style>